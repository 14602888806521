//
// _iniquiry.scss
//

//Bookmark
.bookmark-icon {
    svg {
        fill: transparent;
    }

    .btn-star {
        cursor: pointer;
    }

    .bookmark-input {
        &:checked {
            &~.btn-star svg {
                fill: $warning !important;
                color: $warning !important;
            }
        }
    }
}

.bookmark-hide {
    display: none;
    visibility: hidden;
    height: 0;
}

.inquiry-box, .profile-offcanvas {
    .inquiry-cover {
        display: none;
        position: relative;
        margin-bottom: -50px;
        background: linear-gradient(to right, #363737, #1e3163);
        height: 50px;

        img {
            height: 50px;
            width: 100%;
            object-fit: cover;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, darken($primary, 25%), $primary);
            opacity: 0.6;
        }
    }
}



.inquiry-list {
    &.grid-view-filter {
        flex-flow: row wrap;

        .col {
            flex: 0 0 auto;
            width: 25%;
        }

        p, h6 {
            padding:3px 0px 3px 3px;
         }

        .inquiry-box {
            overflow: hidden;
            // box-shadow: 3px 2px lightgrey;
            box-shadow: 2px 1px lightgrey;
            
            .inquiry-main
            {
                text-align: center;
                margin-top: 10px !important;          
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 25px;
                margin-top: 36px;

                .img-fluid
                {
                       height:100%;
                       width:100%
                }              

                .avatar-lg {
                    height: 4rem;
                    width: 4rem;
                    font-size: 16px;
                }              

                .team-content {
                    margin-left: 0px;
                    margin-top: 25px;
                    text-align: center;
                    width:100%;
                }
            }

            .inquiry-contact
            {
             text-align: center;
             margin-top: 20px !important;         
             
            }

            .inquiry-row {
                align-items: start;

                .col {
                    width: 100%;
                }
            }

            .inquiry-cover {
                display: block;
            }

            .inquiry-status
            {
                text-align: center;
                vertical-align: top;
                margin-top : 5px;
            }

            .inquiry-content {
                margin-top: 10px;
                width:100%;
                text-align: center;
                white-space: nowrap;
            }

            .inquiry-settings {
                .col {
                    width: 50% !important;
                    flex: 0 0 auto;
                }

                .btn-star {
                    color: $white;
                }

                .dropdown {
                    >a {
                        color: $white;
                    }
                }
            }

            .view-btn {
                width: 100%;
                margin-top: 25px;
            }
        }

        .inquiry-box:hover {
             color:red;
             cursor: pointer;
                //scale: 1.05;
                //border: 1px solid $primary;
        }

        @media (min-width: 1200px) and (max-width: 1399.98px) {
            .col {
                flex: 0 0 auto;
                width: 33.33%;
            }
        }

        @media (max-width: 1199.98px) {
            .col {
                flex: 0 0 auto;
                width: 50%;
            }
        }

        @media (max-width: 767.98px) {
            .col {
                flex: 0 0 auto;
                width: 100%;
            }
        }
    }

    &.list-view-filter {
        flex-direction: column;


         p, h6 {
            padding:3px 0px 0px 3px;
         }

        .inquiry-box {
            margin-bottom: 10px;
            //box-shadow: 10px 2px lightgrey;
            box-shadow: 2px 1px lightgrey;

            .inquiry-main
            {
             text-align: left;

             .img-fluid
                {
                       height:100%;
                       width:100%
                }
            }

            .inquiry-contact
            {
             text-align: left;
            }


            .inquiry-row {
                align-items: center;
                justify-content: space-between;
            }

            .inquiry-main {
                display: flex;
                align-items: center;

                .avatar-lg {
                    height: 4rem;
                    width: 4rem;
                    font-size: 16px;
                }

                .inquiry-content {
                    margin-left: 15px;
                    width:100%;
                    text-align: left;
                    white-space: nowrap;
                }
            }

            .inquiry-status
            {
                text-align: center;
                vertical-align: top;
            }

            .inquiry-settings {
               // color:red;
                width: auto;
                 flex: 0 0 auto;
                 -webkit-box-ordinal-group: 7;
                 //order: 2;
            }

            .btn-star {
                color: $primary;
            }
        }


        .inquiry-box:hover {
            color: red;
            cursor: pointer;
            //scale: 1.01;
            //border: 1px solid $primary;
        }

        @media (max-width: 767.98px) {
            flex-direction: row;
            .col {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: 100%;
            }
            .inquiry-box {

                .inquiry-settings {
                    width: 100%;
                    flex: 0 0 auto;
                    order: -1;
                    margin-bottom: 10px;

                    .col {
                        width: 50%;
                    }
                }

                .inquiry-main {
                    margin-bottom: 25px;
                }

                .view-btn {
                    width: 100%;
                    margin-top: 25px;
                }
            }
        }
    }
}

.list-grid-nav {
    .nav-link {
        &.active {
            background-color: $info;
            color: $white;
        }
    }
}

.profile-offcanvas  {
    .inquiry-cover {
        margin-bottom: -132px;
        display: block;
        z-index: -1;
    }
    .btn-star {
        color: $white;
    }

    .dropdown {
        >a {
            color: $white;
        }
    }
}