.dropzonecustom {
  min-height: 50px !important;
}
.cardItems {
  height: 67vh;
  max-height: 67vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.descriptionPackage {
  height: 50px;
  overflow-y: hidden;
}
