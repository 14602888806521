.ant-table-thead .ant-table-cell {
  z-index: 1 !important;
  padding: 10px !important;
  // background-color: red !important;
}


.ant-table-tbody .ant-table-cell {
  background-color: #fff !important;
  border: 1px solid #eaedef;
  z-index: 1 !important;
  padding: 2px !important;
  // background-color:  blue !important;
}


